import { type FC, type PropsWithChildren } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import GsSidebar from 'components/templates/DashboardMain/GsSideber';
import GsHeader from './GsHeader.tsx';

const DashboardMain: FC<PropsWithChildren> = () => {
  return (
    <>
      <div id="dashboard">
        <GsHeader />
        <main className="main-area mx-auto flex-1 w-full flex">
          <GsSidebar />
          <div className="relative h-full w-5/6 px-3 overflow-y-scroll">
            <Outlet />
          </div>
        </main>
      </div>
      <ScrollRestoration />
    </>
  );
};

export default DashboardMain;
